import React from 'react';
import PropTypes from 'prop-types';
import toCSS from 'style-to-css';

const getFontName = fontName => {
  if (fontName === 'Roboto Mono') {
    return '\'Roboto Mono\', monospace';
  }
  if (fontName === 'Open Sans') {
    return '\'Open Sans\', sans-serif';
  }

  return '';
};

const fontStyle = (fontName, fontColor) => {
  const fontStyle = {};
  if (fontName && fontName !== 'Default') {
    const font = getFontName(fontName);
    if (font) {
      fontStyle.fontFamily = font;
    }
  }
  if (fontColor) {
    fontStyle.color = fontColor.hex;
  }

  return fontStyle;
};

const getMainStyle = ({
  backgroundColor,
  textFont,
  textColor,
  borderPosition,
  borderStyle,
  borderWidth,
  borderColor,
}) => {
  const styleObj = { ...fontStyle(textFont, textColor) };
  if (backgroundColor) {
    styleObj.backgroundColor = backgroundColor.hex;
  }

  if (borderPosition && borderPosition !== 'None') {
    const width = borderWidth || 1;
    const color = (borderColor && borderColor.hex) || '#dae3e3';
    const style = borderStyle || 'solid';

    if (style !== 'none') {
      switch (borderPosition) {
      case 'All':
        styleObj.border = `${width}px ${style} ${color}`;
        break;
      case 'Top':
        styleObj.borderTop = `${width}px ${style} ${color}`;
        break;
      case 'Bottom':
        styleObj.borderBottom = `${width}px ${style} ${color}`;
        break;
      case 'Left':
        styleObj.borderLeft = `${width}px ${style} ${color}`;
        break;
      case 'Right':
        styleObj.borderRight = `${width}px ${style} ${color}`;
        break;
      case 'Top-Bottom':
        styleObj.borderTop = `${width}px ${style} ${color}`;
        styleObj.borderBottom = `${width}px ${style} ${color}`;
        break;
      case 'Left-Right':
        styleObj.borderLeft = `${width}px ${style} ${color}`;
        styleObj.borderRight = `${width}px ${style} ${color}`;
        break;
      }
    }
  }

  return styleObj;
};

const getTagStyle = ({ tagFont, tagFontColor }) => {
  const styleObj = { ...fontStyle(tagFont, tagFontColor) };

  if (tagFontColor) {
    styleObj.borderBottomColor = tagFontColor.hex;
  }

  return styleObj;
};

const getTitleStyle = ({ titleFont, titleFontColor }) => {
  return fontStyle(titleFont, titleFontColor);
};

const getDescriptionStyle = ({ descriptionFont, descriptionFontColor }) => {
  return fontStyle(descriptionFont, descriptionFontColor);
};

const getNoteStyle = ({ noteFont, noteFontColor }) => {
  return fontStyle(noteFont, noteFontColor);
};

const StyleGenerator = ({ style, blockClassName, classPrefix }) => {
  if (!style) {
    return null;
  }

  const { linkColor, linkColorHover } = style;
  let mainCssString = '';

  const mainStyle = getMainStyle(style);
  if (Object.keys(mainStyle).length > 0) {
    mainCssString += `
    .${blockClassName} {
      ${toCSS(mainStyle)}
    }
    `;
  }

  if (linkColor) {
    mainCssString += `
    .${blockClassName} a {
      color: ${linkColor.hex}
    }
    `;
  }

  if (linkColorHover) {
    mainCssString += `
    .${blockClassName} a:hover {
     color: ${linkColorHover.hex}
    }
    `;
  }

  const tagStyle = getTagStyle(style);
  if (Object.keys(tagStyle).length > 0) {
    mainCssString += `
    .${blockClassName} .${classPrefix}__tag {
      ${toCSS(tagStyle)}
    }
    `;
  }

  const titleStyle = getTitleStyle(style);
  if (Object.keys(titleStyle).length > 0) {
    mainCssString += `
    .${blockClassName} .${classPrefix}__title {
      ${toCSS(titleStyle)}
    }
    `;
  }

  const descriptionStyle = getDescriptionStyle(style);
  if (Object.keys(descriptionStyle).length > 0) {
    mainCssString += `
    .${blockClassName} .${classPrefix}__description {
      ${toCSS(descriptionStyle)}
    }
    `;
  }

  const noteStyle = getNoteStyle(style);
  if (Object.keys(noteStyle).length > 0) {
    mainCssString += `
    .${blockClassName} .${classPrefix}__note {
      ${toCSS(noteStyle)}
    }
    `;
  }

  return (
    mainCssString && (
      <style
        dangerouslySetInnerHTML={{
          __html: mainCssString,
        }}
      />
    )
  );
};

export const StyleRecord = PropTypes.shape({
  name: PropTypes.string.isRequired,
  backgroundColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  textFont: PropTypes.string,
  textColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  linkColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  linkColorHover: PropTypes.shape({
    hex: PropTypes.string,
  }),
  tagFont: PropTypes.string,
  tagFontColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  titleFont: PropTypes.string,
  titleFontColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  descriptionFont: PropTypes.string,
  descriptionFontColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  noteFont: PropTypes.string,
  noteFontColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  borderPosition: PropTypes.string,
  borderStyle: PropTypes.string,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
});

StyleGenerator.defaultProps = {
  classPrefix: 'content-page',
};

StyleGenerator.propTypes = {
  blockClassName: PropTypes.string.isRequired,
  style: StyleRecord,
  classPrefix: PropTypes.string,
};

export default StyleGenerator;
