import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import EventList from './EventList';

const EventCardListContent = ({
  id,
  title,
  description,
  cards,
  style,
  comingSoon,
}) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames('content-page', 'events-content', blockClassName)}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        {title && (
          <div
            className="content-page__title"
            dangerouslySetInnerHTML={{ __html: title }}></div>
        )}

        {description && (
          <div className="content-page__content">
            <div
              className="content-page__description"
              dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        )}

        <div className="content-page__box">
          <EventList list={cards} comingSoon={comingSoon} />
        </div>
      </div>
    </div>
  );
};

EventCardListContent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  comingSoon: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      button: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  ),
  style: StyleRecord,
};

export default EventCardListContent;
