import gql from 'graphql-tag';

export const FRAGMENT_IMAGE_RECORD = gql`
  fragment imageRecord on ImageRecord {
    id
    name
    image {
      url
    }
    __typename
  }
`;

export const FRAGMENT_QUOTE_RECORD = gql`
  fragment quoteRecord on QuoteRecord {
    id
    author
    quote(markdown: true)
    __typename
  }
`;

export const FRAGMENT_TEXT_RECORD = gql`
  fragment textRecord on TextRecord {
    id
    paragraphs(markdown: true)
    name
    __typename
  }
`;

export const FRAGMENT_FEATURE_RECORD = gql`
  fragment featureRecord on FeatureRecord {
    id
    description(markdown: true)
    title
    __typename
  }
`;

export const FRAGMENT_LINK_OBJECT = gql`
  fragment linkRecord on LinkRecord {
    __typename
    id
    title
    url
    description
    blank
    icon {
      id
      url
      alt
      format
    }
  }
`;

export const FRAGMENT_STYLE_RECORD = gql`
  fragment styleRecord on StyleRecord {
    __typename
    id
    name
    backgroundColor {
      hex
    }
    textFont
    textColor {
      hex
    }
    linkColor {
      hex
    }
    linkColorHover {
      hex
    }
    tagFont
    tagFontColor {
      hex
    }
    titleFont
    titleFontColor {
      hex
    }
    descriptionFont
    descriptionFontColor {
      hex
    }
    noteFont
    noteFontColor {
      hex
    }
    borderPosition
    borderStyle
    borderWidth
    borderColor {
      hex
    }
  }
`;

export const FRAGMENT_DESCRIPTION_RECORD = gql`
  fragment descriptionRecord on DescriptionRecord {
    id
    tag
    title
    subtitle(markdown: true)
    description(markdown: true)
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_DESCRIPTION_VIDEO_RECORD = gql`
  fragment descriptionVideoRecord on DescriptionVideoRecord {
    __typename
    id
    title(markdown: true)
    tag
    description(markdown: true)
    button {
      ...linkRecord
    }
    rightSide
    textOnTop
    video {
      url
      provider
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_DESCRIPTION_IMAGE_RECORD = gql`
  fragment descriptionImageRecord on DescriptionImageRecord {
    __typename
    id
    title(markdown: true)
    tag
    description(markdown: true)
    button {
      ...linkRecord
    }
    rightSide
    textOnTop
    image {
      id
      alt
      url
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_BANNER_RECORD = gql`
  fragment bannerRecord on BannerRecord {
    __typename
    id
    title(markdown: true)
    button {
      ...linkRecord
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_VIDEO_LIST_RECORD = gql`
  fragment videoListRecord on VideoListRecord {
    __typename
    id
    title(markdown: true)
    description(markdown: true)
    video {
      url
      provider
    }
    videos {
      id
      title(markdown: true)
      description(markdown: true)
      documentLink {
        ...linkRecord
      }
      comingSoon {
        url
      }
      video {
        provider
        url
      }
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_CARD_LIST_RECORD = gql`
  fragment cardListRecord on CardListRecord {
    __typename
    id
    title(markdown: true)
    description(markdown: true)
    cards {
      id
      tag
      title
      description(markdown: true)
      image {
        url
      }
      button {
        ...linkRecord
      }
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_EVENT_CARD_LIST_RECORD = gql`
  fragment eventCardListRecord on EventCardListRecord {
    __typename
    id
    title(markdown: true)
    description(markdown: true)
    comingSoon
    cards {
      id
      tag
      title
      description(markdown: true)
      image {
        url
      }
      button {
        ...linkRecord
      }
      comingSoon
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_GUIDES_INFO_RECORD = gql`
  fragment guidesInfoRecord on GuidesInfoRecord {
    __typename
    id
    title(markdown: true)
    description(markdown: true)
    documentLinks {
      ...linkRecord
    }
    loadMoreButton
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_INSTAGRAM_RECORD = gql`
  fragment instagramRecord on InstagramRecord {
    __typename
    id
    tag
    title(markdown: true)
    description(markdown: true)
    defaultImage {
      url
    }
    showPostTitle
    posts {
      ...linkRecord
    }
    url {
      ...linkRecord
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_HERO_BANNER_RECORD = gql`
  fragment heroBannerRecord on HeroBannerRecord {
    __typename
    id
    tag
    title(markdown: true)
    description(markdown: true)
    note(markdown: true)
    button {
      ...linkRecord
    }
    heroBannerHeight
    heroBanner {
      id
      url
      height
      width
    }
    tabletHeroBannerHeight
    tabletHeroBanner {
      id
      url
      height
      width
    }
    phoneHeroBannerHeight
    phoneHeroBanner {
      id
      url
      height
      width
    }
    style {
      ...styleRecord
    }
  }
  ${FRAGMENT_LINK_OBJECT}
  ${FRAGMENT_STYLE_RECORD}
`;

export const FRAGMENT_TWO_COLUMNS_RECORD = gql`
  fragment twoColumnsContentRecord on TwoColumnsContentRecord {
    __typename
    heading(markdown: true)
    subheading
    leftBackgroundImage {
      url
      id
    }
    rightBackgroundImage {
      url
      id
    }
    primary {
      ...imageRecord
      ...quoteRecord
      ...textRecord
    }
    secondary {
      ...textRecord
      ...imageRecord
      ...featureRecord
      ...quoteRecord
    }
    backgroundColor {
      hex
    }
  }
  ${FRAGMENT_IMAGE_RECORD}
  ${FRAGMENT_TEXT_RECORD}
  ${FRAGMENT_QUOTE_RECORD}
  ${FRAGMENT_FEATURE_RECORD}
`;
