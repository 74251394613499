import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import UILink from '../../UILink';

const Banner = ({ title, style, button, id }) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div className={classnames('content-page', 'banner', blockClassName)}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        <div
          className="content-page__title"
          dangerouslySetInnerHTML={{ __html: title }}></div>
        {button && (
          <UILink
            {...button}
            className="content-page__button"
            blank={button.blank}
          />
        )}
      </div>
    </div>
  );
};

Banner.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    blank: PropTypes.bool,
    icon: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  }),
  style: StyleRecord,
};

export default Banner;
