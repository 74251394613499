import React, { Suspense, useEffect } from 'react';
import { DATO_CMS_GQL_API, DATO_CMS_API_TOKEN, BASE_URL } from '../../env.js';
import fragmentTypes from './fragmentTypes.json';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { LayoutProvider } from './layout-context';
import Layout from './Layout';
import Loader from '../ui-common/Loader';

import './App.scss';

import Home from '../Home';
import createStore from '../../store/createStore';
const ContactUs = React.lazy(() => import('../ContactUs'));
const DefaultTemplate = React.lazy(() => import('../DefaultTemplate'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const client = new ApolloClient({
  uri: DATO_CMS_GQL_API,
  request: async operation => {
    operation.setContext({
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${DATO_CMS_API_TOKEN}`,
      },
    });
  },
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes,
    }),
  }),
});

const store = createStore();
const App = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Router basename={BASE_URL}>
          <ScrollToTop />
          <LayoutProvider>
            <Layout>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact
                    path="/"
                    component={Home}
                  />
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact
                    path="/contact-us"
                    component={ContactUs}
                  />
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact
                    path="/:slug"
                    component={DefaultTemplate}
                  />
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    render={() => <Redirect to="/" />}
                  />
                </Switch>
              </Suspense>
            </Layout>
          </LayoutProvider>
        </Router>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
