import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import GuideList from './GuideList';

const GuidesContent = ({
  id,
  title,
  description,
  documentLinks,
  loadMoreButton,
  style,
}) => {
  const [showCount, setShowCount] = useState(5);
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames('content-page', 'guides-content', blockClassName)}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        <div
          className="content-page__title"
          dangerouslySetInnerHTML={{ __html: title }}></div>

        {description && (
          <div className="content-page__content">
            <div
              className="content-page__description"
              dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        )}

        <div className="content-page__box">
          <GuideList list={documentLinks.slice(0, showCount)} />
        </div>

        {showCount < documentLinks.length && (
          <div
            className="content-page__link-button"
            onClick={() => {
              setShowCount(showCount + 3);
            }}>
            {loadMoreButton}
          </div>
        )}
      </div>
    </div>
  );
};

GuidesContent.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  documentLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      blank: PropTypes.bool,
      icon: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string.isRequired,
      }),
    }),
  ),
  loadMoreButton: PropTypes.string,
  style: StyleRecord,
};

export default GuidesContent;
