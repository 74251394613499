import { call, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import AuthenticationTypes from './types';

/**
 * Checks if the user is authenticated. If not, tries
 * to authenticate via the header-footer Auth0 method.
 */
const login = function* login() {
  yield put(actions.loginRequested());
  try {
    // get token
    yield call(window.auth.getAccessToken);
    // get profile
    const profile = yield call(window.auth.getProfile);
    yield put(actions.loginSucceeded(profile));
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
};

// get Profile
const getProfile = function* getProfile() {
  yield put(actions.getProfileRequested());
  try {
    const profile = yield call(window.auth.getProfile);
    yield put(actions.getProfileSucceeded(profile));
  } catch (error) {
    yield put(actions.getProfileFailed(error));
  }
};

const watchLogin = function* watchLogin() {
  yield takeEvery(AuthenticationTypes.LOGIN, login);
};

const watchLoginSuccess = function* watchLoginSuccess() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, loginSuccess);
};

const loginSuccess = function* loginSuccess() {
  yield fork(getProfile);
};

const watchGetProfile = function* watchGetProfile() {
  yield takeEvery(AuthenticationTypes.GET_PROFILE, loginSuccess);
};

export default [watchLogin, watchLoginSuccess, watchGetProfile];
