/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TextObject from './TextObject';
import FeatureObject from './FeatureObject';
import ImageObject from './ImageObject';
import QuoteObject from './QuoteObject';

import './TwoColumnsContent.scss';

const ContentItems = {
  TextRecord: TextObject,
  QuoteRecord: QuoteObject,
  ImageRecord: ImageObject,
  FeatureRecord: FeatureObject,
};

const TwoColumnsContent = ({
  heading,
  subheading,
  primary,
  secondary,
  id,
  leftBackgroundImage,
  rightBackgroundImage,
  className,
  leftImageStyle,
  backgroundColor,
}) => {
  const style = backgroundColor ? { backgroundColor: backgroundColor.hex } : {};
  return (
    <div className={classnames(className, 'two-columns-content')} style={style}>
      <div className="main-container">
        <div className="two-columns-content__item" key={id}>
          <div className="two-columns-content__header">{subheading}</div>
          <div className="two-columns-content__content">
            <div
              className="two-columns-content__left"
              style={
                leftBackgroundImage
                  ? {
                      backgroundImage: `url(${leftBackgroundImage.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      ...leftImageStyle,
                    }
                  : {}
              }>
              {primary.map((item, index) => {
                const Tag = ContentItems[item.__typename];
                if (Tag) {
                  return (
                    <Tag
                      key={item.id}
                      {...item}
                      heading={index === 0 ? heading : null}
                    />
                  );
                } else {
                  // eslint-disable-next-line no-console
                  console.error('Unknown type', item.__typename);
                  return null;
                }
              })}
            </div>
            {!!secondary.length && (
              <div
                className={classnames('two-columns-content__right', {
                  'no-left-content': primary.length === 0,
                  'not-only-right-content': primary.length > 0,
                })}
                style={
                  rightBackgroundImage
                    ? {
                        backgroundImage: `url(${rightBackgroundImage.url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }
                    : {}
                }>
                {secondary.map(item => {
                  const Tag = ContentItems[item.__typename];
                  if (Tag) {
                    return <Tag key={item.id} {...item} secondary={true} />;
                  } else {
                    // eslint-disable-next-line no-console
                    console.error('Unknown type', item.__typename);
                    return null;
                  }
                })}
              </div>
            )}
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

TwoColumnsContent.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  primary: PropTypes.arrayOf(PropTypes.object),
  secondary: PropTypes.arrayOf(PropTypes.object),
  leftBackgroundImage: PropTypes.shape({
    url: PropTypes.string,
    id: PropTypes.string,
  }),
  rightBackgroundImage: PropTypes.shape({
    url: PropTypes.string,
    id: PropTypes.string,
  }),
  className: PropTypes.string,
  leftImageStyle: PropTypes.object,
  backgroundColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
};

export default TwoColumnsContent;
