export default class NetworkError extends Error {
  constructor(response, body) {
    const { status, statusText, url } = response || {};
    super(statusText);

    try {
      Error.captureStackTrace(this, NetworkError);
    } catch (error) {
      // noop
    }

    this.status = status;
    this.statusText = statusText;
    this.url = url;
    this.body = body;
  }
}
