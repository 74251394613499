import types from './types';

const initialState = {
  login: {
    inProgress: false,
    didInvalidate: false,
    lastUpdate: null,
    loggedIn: false,
    error: null,
  },
  profile: {
    data: {},
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
  kits: {
    data: {},
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
};

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
// prettier-ignore
export default function example(state = initialState, action) {
  switch (action.type) {
  case types.LOGIN_REQUESTED:
    return {
      ...state,
      login: {
        ...state.login,
        inProgress: true,
        loggedIn: false,
      },
    };
  case types.LOGIN_SUCCEEDED:
    return {
      ...state,
      login: {
        ...state.login,
        inProgress: false,
        loggedIn: true,
        lastUpdate: Date.now(),
        profile: action.profile,
      },
    };
  case types.LOGIN_FAILED:
    return {
      ...state,
      login: {
        ...state.login,
        inProgress: false,
        loggedIn: false,
        lastUpdate: Date.now(),
        error: action.error,
      },
    };
  case types.GET_PROFILE_REQUESTED:
    return {
      ...state,
      profile: {
        ...state.profile,
        inProgress: true,
      },
    };
  case types.GET_PROFILE_SUCCEEDED:
    return {
      ...state,
      login: {
        ...state.login,
        loggedIn: true,
      },
      profile: {
        ...state.profile,
        data: action.profile,
        lastUpdate: Date.now(),
        inProgress: false,
      },
    };
  case types.GET_PROFILE_FAILED:
    return {
      ...state,
      profile: {
        ...state.profile,
        error: action.error,
        lastUpdate: Date.now(),
        inProgress: false,
      },
    };
  case types.GET_KITS_REQUESTED:
    return {
      ...state,
      kits: {
        ...state.kits,
        inProgress: true,
      },
    };
  case types.GET_KITS_SUCCEEDED:
    return {
      ...state,
      kits: {
        ...state.kits,
        data: action.kits || {},
        lastUpdate: Date.now(),
        inProgress: false,
      },
    };
  case types.GET_KITS_FAILED:
    return {
      ...state,
      kits: {
        ...state.kits,
        error: action.error,
        lastUpdate: Date.now(),
        inProgress: false,
      },
    };
  default:
    return state;
  }
}
