import React from 'react';
import PropTypes from 'prop-types';

import ContentsPage from '../ui-common/ContentsPage';

const Home = ({ data: { home } }) => {
  const { content } = home;

  return (
    <div className="home">
      <ContentsPage contents={content} />
    </div>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    home: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      heroBanner: PropTypes.shape({
        url: PropTypes.string,
      }),
      lastUpdated: PropTypes.string,
      content: PropTypes.array,
    }),
  }),
};

export default Home;
