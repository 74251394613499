import React from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';
import classnames from 'classnames';

import VideoList from './VideoList';
import Style, { StyleRecord } from '../StyleGenerator';

const VideosContent = ({ id, title, description, video, videos, style }) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames(
        'content-page',
        'videos-content',
        blockClassName,
        `${video ? 'video-exist' : 'video-not-exist'}`,
      )}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        <div
          className="content-page__title"
          dangerouslySetInnerHTML={{ __html: title }}></div>

        <div className="content-page__content">
          {video && (
            <div className="content-page__video">
              <ReactPlayer
                url={video.url}
                width={'auto'}
                light={true}
                controls
              />
            </div>
          )}
          <div className="content-page__text-box">
            {description && (
              <div
                className="content-page__description"
                dangerouslySetInnerHTML={{ __html: description }}></div>
            )}
          </div>
        </div>

        <VideoList list={videos} />
      </div>
    </div>
  );
};

VideosContent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string,
    provider: PropTypes.string,
  }),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      documentLink: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      video: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  ),
  style: StyleRecord,
};

export default VideosContent;
