import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import UILink from '../../UILink';

const DescriptionImageContent = ({
  id,
  tag,
  title,
  description,
  image,
  button,
  rightSide,
  textOnTop,
  style,
}) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames(
        'content-page',
        'description-image',
        blockClassName,
        {
          'image-exist': image,
          'image-not-exist': !image,
          'right-side': rightSide,
          'left-side': !rightSide,
          'text-on-top': textOnTop,
        },
      )}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        {tag && (
          <div
            className="content-page__tag"
            dangerouslySetInnerHTML={{ __html: tag }}></div>
        )}
        {title && (
          <div
            className="content-page__title"
            dangerouslySetInnerHTML={{ __html: title }}></div>
        )}

        <div className="content-page__content">
          {image && (
            <div className="content-page__image">
              <img src={image.url} alt={image.alt} />
            </div>
          )}
          <div className="content-page__text-box">
            {description && (
              <div
                className="content-page__description"
                dangerouslySetInnerHTML={{ __html: description }}></div>
            )}
            {button && (
              <div className="content-page__actions">
                <UILink {...button} className="content-page__button" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DescriptionImageContent.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    blank: PropTypes.bool,
    icon: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  }),
  rightSide: PropTypes.bool,
  textOnTop: PropTypes.bool,
  image: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    url: PropTypes.string,
  }),
  style: StyleRecord,
};

export default DescriptionImageContent;
