import React from 'react';
import PropTypes from 'prop-types';

const ImageObject = ({ __typename, image }) => {
  return (
    <div className={`two-columns-content__image type--${__typename}`}>
      <img src={image.url} alt={image.title} />
    </div>
  );
};

ImageObject.propTypes = {
  __typename: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default ImageObject;
