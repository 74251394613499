import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getEmbeddedContent } from '../../../../apis/instagram';

import Loader from '../../Loader/Loader';

const InstagramPost = ({ url, defaultImage, showPostTitle }) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [imageUrl, setImageUrl] = useState(defaultImage);

  useEffect(() => {
    if (showPostTitle) {
      getEmbeddedContent(url).then(json => {
        setData(json);
        setImageUrl(`${url}media/?size=m`);
        setLoaded(true);
      });
    } else {
      setImageUrl(`${url}media/?size=m`);
      setLoaded(true);
    }
  }, []);

  return (
    <div className="instagram-post">
      {loaded ? (
        <a
          className="instagram-post__content"
          href={url}
          target="_blank"
          rel="noopener noreferrer">
          <div className="instagram-post__image">
            <img
              src={imageUrl}
              onError={() => {
                setImageUrl(defaultImage);
              }}
              alt="instagram"
            />
          </div>
          {data && (
            <div className="instagram-post__text">
              <span>{data.title}</span>
            </div>
          )}
        </a>
      ) : (
        <Loader className="instagram-post__loading" />
      )}
    </div>
  );
};

InstagramPost.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  defaultImage: PropTypes.string.isRequired,
  showPostTitle: PropTypes.bool,
};

export default InstagramPost;
