import gql from 'graphql-tag';

import Home from './Home';
import QueryWrapper from '../../utils/QueryWrapper';
import {
  FRAGMENT_DESCRIPTION_VIDEO_RECORD,
  FRAGMENT_DESCRIPTION_IMAGE_RECORD,
  FRAGMENT_BANNER_RECORD,
  FRAGMENT_VIDEO_LIST_RECORD,
  FRAGMENT_CARD_LIST_RECORD,
  FRAGMENT_GUIDES_INFO_RECORD,
  FRAGMENT_INSTAGRAM_RECORD,
  FRAGMENT_HERO_BANNER_RECORD,
  FRAGMENT_TWO_COLUMNS_RECORD,
  FRAGMENT_DESCRIPTION_RECORD,
  FRAGMENT_EVENT_CARD_LIST_RECORD,
} from '../../utils/query';

import { APP_BASE_SLUG } from '../../env';

const QUERY = gql`
  query($locale: SiteLocale!, $appSlug: String!) {
    home: contentPage(locale: $locale, filter: { slug: { eq: $appSlug } }) {
      id
      content {
        __typename
        ...descriptionVideoRecord
        ...bannerRecord
        ...videoListRecord
        ...cardListRecord
        ...guidesInfoRecord
        ...instagramRecord
        ...heroBannerRecord
        ...twoColumnsContentRecord
        ...descriptionImageRecord
        ...descriptionRecord
        ...eventCardListRecord
      }
    }
  }
  ${FRAGMENT_DESCRIPTION_VIDEO_RECORD}
  ${FRAGMENT_BANNER_RECORD}
  ${FRAGMENT_VIDEO_LIST_RECORD}
  ${FRAGMENT_CARD_LIST_RECORD}
  ${FRAGMENT_GUIDES_INFO_RECORD}
  ${FRAGMENT_INSTAGRAM_RECORD}
  ${FRAGMENT_HERO_BANNER_RECORD}
  ${FRAGMENT_TWO_COLUMNS_RECORD}
  ${FRAGMENT_DESCRIPTION_IMAGE_RECORD}
  ${FRAGMENT_DESCRIPTION_RECORD}
  ${FRAGMENT_EVENT_CARD_LIST_RECORD}
`;

const HomeContainer = props => {
  return QueryWrapper(QUERY, Home, props, {
    appSlug: APP_BASE_SLUG,
  });
};

export default HomeContainer;
