import React from 'react';
import PropTypes from 'prop-types';

const GuideList = ({ list }) => {
  const content = link => {
    const { icon, title } = link;
    if (icon) {
      return <img src={icon.url} alt={icon.alt || title} />;
    }

    return <span>{title}</span>;
  };
  return (
    <div className="guide-list">
      {list.map(docLink => {
        const href = {};
        href.href = docLink.url;
        if (docLink.blank) {
          href.target = '_blank';
          href.rel = 'noopener noreferrer';
        }
        return (
          <div key={docLink.id} className="guide-list__row">
            <a className="guide-item" {...href}>
              <div className="guide-item__title">{docLink.description}</div>
              <div className="guide-item__action">
                <div className="guide-item__link">{content(docLink)}</div>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

GuideList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      blank: PropTypes.bool,
      icon: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default GuideList;
