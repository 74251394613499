import React from 'react';
import PropTypes from 'prop-types';

const QuoteObject = ({ __typename, quote, author }) => {
  if (!quote) {
    return null;
  }

  return (
    <blockquote
      className={`two-columns-content__blockquote type--${__typename}`}>
      <p
        className="two-columns-content__text"
        dangerouslySetInnerHTML={{ __html: quote }}
      />
      <cite>{author}</cite>
    </blockquote>
  );
};

QuoteObject.propTypes = {
  __typename: PropTypes.string,
  author: PropTypes.string,
  quote: PropTypes.string,
};

export default QuoteObject;
