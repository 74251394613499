import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import UILink from '../../UILink';
import Style, { StyleRecord } from '../StyleGenerator';

const HeroBannerContent = ({
  id,
  title,
  description,
  note,
  button,
  heroBanner,
  tabletHeroBanner,
  phoneHeroBanner,
  color,
  tag,
  style,
  heroBannerHeight,
  tabletHeroBannerHeight,
  phoneHeroBannerHeight,
}) => {
  const tablet = tabletHeroBanner ? tabletHeroBanner : heroBanner;
  const phone = phoneHeroBanner ? phoneHeroBanner : tablet;

  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames(
        'content-page',
        'hero-banner',
        'cover',
        blockClassName,
      )}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .hero-banner {
        background-image: url(${heroBanner.url});
        height: ${heroBannerHeight}px;
      }
      @media screen and (max-width: 992px) {
        .hero-banner {
          background-image: url(${tablet.url});
          height: ${tabletHeroBannerHeight}px;
        }
      }
      @media screen and (max-width: 480px) {
        .hero-banner {
          background-image: url(${phone.url});
          height: ${phoneHeroBannerHeight}px;
        }
      }
     `,
        }}
      />
      <Style
        blockClassName={blockClassName}
        style={style}
        classPrefix="hero-banner"
      />
      <div className="hero-banner__box">
        <div className="hero-banner__content">
          {tag && (
            <div
              className="hero-banner__tag"
              dangerouslySetInnerHTML={{ __html: tag }}
              style={color ? { borderBottomColor: color.hex } : {}}
            />
          )}
          {title && (
            <div
              className="hero-banner__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div
              className="hero-banner__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {note && (
            <div
              className="hero-banner__note"
              dangerouslySetInnerHTML={{ __html: note }}></div>
          )}
          {button && (
            <div className="content-page__actions">
              <UILink {...button} className="content-page__button" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HeroBannerContent.propTypes = {
  id: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  note: PropTypes.string,
  button: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    blank: PropTypes.bool,
    icon: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  }),
  color: PropTypes.shape({
    hex: PropTypes.string,
  }),
  heroBanner: PropTypes.shape({
    url: PropTypes.string,
    height: PropTypes.number,
  }),
  tabletHeroBanner: PropTypes.shape({
    url: PropTypes.string,
    height: PropTypes.number,
  }),
  phoneHeroBanner: PropTypes.shape({
    url: PropTypes.string,
    height: PropTypes.number,
  }),
  heroBannerHeight: PropTypes.number,
  tabletHeroBannerHeight: PropTypes.number,
  phoneHeroBannerHeight: PropTypes.number,
  style: StyleRecord,
};

export default HeroBannerContent;
