import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import UILink from '../../UILink';
import Icon from '../../icon';

import InstagramPost from './InstagramPost';

const InstagramContent = ({
  id,
  tag,
  title,
  description,
  posts,
  url,
  defaultImage,
  showPostTitle,
  style,
}) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames(
        'content-page',
        'instagram-content',
        blockClassName,
      )}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        {tag && (
          <div
            className="content-page__tag"
            dangerouslySetInnerHTML={{ __html: tag }}></div>
        )}
        {title && (
          <div
            className="content-page__title"
            dangerouslySetInnerHTML={{ __html: title }}></div>
        )}
        {description && (
          <div className="content-page__content">
            <div
              className="content-page__description"
              dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        )}

        {posts.length && (
          <div className="content-page__box">
            <div className="instagram-content__row">
              <div className="instagram-content__column">
                <InstagramPost
                  {...posts[0]}
                  defaultImage={defaultImage.url}
                  showPostTitle={showPostTitle}
                />
              </div>
              <div className="instagram-content__column">
                <div className="instagram-posts">
                  {posts.slice(1).map(post => (
                    <div key={post.id} className="instagram-posts__item">
                      <InstagramPost
                        {...post}
                        defaultImage={defaultImage.url}
                        showPostTitle={showPostTitle}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {url && (
          <div className="content-page__actions">
            <UILink className="content-page__link-button more-posts" {...url}>
              <span>{url.title}</span>
              <Icon name="link" />
            </UILink>
          </div>
        )}
      </div>
    </div>
  );
};

InstagramContent.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  defaultImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  showPostTitle: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      blank: PropTypes.bool,
      icon: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string.isRequired,
      }),
    }),
  ),
  url: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    blank: PropTypes.bool,
    icon: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  }),
  style: StyleRecord,
};

export default InstagramContent;
