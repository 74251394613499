import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ItemsCarousel from 'react-items-carousel';

import Icon from '../../icon';
import VideoItem from './VideoItem';

function getNumberByWidth(width) {
  if (width <= 480) {
    return 1;
  }

  if (width <= 768) {
    return 2;
  }

  return 3;
}

const VideoList = ({ list }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [showItems, setShowItems] = useState(3);
  const chevronWidth = 30;

  useEffect(() => {
    setShowItems(getNumberByWidth(window.innerWidth));
    window.addEventListener('resize', () => {
      setShowItems(getNumberByWidth(window.innerWidth));
    });
  }, []);

  return (
    <div className="video-list">
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={showItems}
        gutter={0}
        leftChevron={
          <div
            className={`video-list__control ${
              activeItemIndex <= 0 ? 'disable' : ''
            }`}>
            <Icon name="arrow-left" className="left" />
          </div>
        }
        rightChevron={
          <div
            className={`video-list__control ${
              activeItemIndex + showItems >= list.length ? 'disable' : ''
            }`}>
            <Icon name="arrow-right" className="right" />
          </div>
        }
        //alwaysShowChevrons
        showSlither
        slidesToScroll={showItems}
        chevronWidth={chevronWidth}>
        {list.map(videoItem => (
          <VideoItem key={videoItem.id} {...videoItem} />
        ))}
      </ItemsCarousel>
    </div>
  );
};

VideoList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      documentLink: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      comingSoon: PropTypes.shape({
        url: PropTypes.string,
      }),
      video: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  ),
};

export default VideoList;
