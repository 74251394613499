import React from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

const VideoItem = ({ title, description, video, comingSoon }) => {
  return (
    <div className="video-item">
      <div className="video-item__content">
        {comingSoon ? (
          <div className="video-item__image">
            <img src={comingSoon.url} alt="Coming Soon"></img>
          </div>
        ) : (
          <div className="video-item__video">
            {video && (
              <ReactPlayer
                url={video.url}
                width={'auto'}
                light={true}
                controls
              />
            )}
          </div>
        )}
        <div
          className="video-item__title"
          dangerouslySetInnerHTML={{ __html: title }}></div>
        {description && (
          <div
            className="video-item__description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}></div>
        )}
      </div>
    </div>
  );
};

VideoItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  documentLink: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  comingSoon: PropTypes.shape({
    url: PropTypes.string,
  }),
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default VideoItem;
