import React from 'react';
import PropTypes from 'prop-types';

const CardList = ({ list }) => {
  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className="card-list">
      <div className="card-list__row">
        {list.map(item => {
          const href = {};
          if (item.button) {
            href.href = item.button.url;
            if (item.button.blank) {
              href.target = '_blank';
              href.rel = 'noopener noreferrer';
            }
          }
          return (
            <div key={item.id} className="card-list__item">
              <a className="card-item" {...href}>
                <div className="card-item__content">
                  <div className="card-item__image">
                    {item.image && (
                      <img src={item.image.url} alt="Coming Soon"></img>
                    )}
                  </div>
                  <div className="card-item__text-content">
                    {item.tag && (
                      <div
                        className="card-item__tag"
                        dangerouslySetInnerHTML={{ __html: item.tag }}
                      />
                    )}
                    {item.title && (
                      <div
                        className="card-item__title"
                        dangerouslySetInnerHTML={{ __html: item.title }}></div>
                    )}
                    {item.description && (
                      <div
                        className="card-item__description"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}></div>
                    )}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CardList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      button: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        blank: PropTypes.blank,
        id: PropTypes.string,
      }),
    }),
  ),
};

export default CardList;
