import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const isExternalLink = url => {
  const host = window.location.hostname;

  const linkHost = (url => {
    if (/^https?:\/\//.test(url)) {
      const parser = document.createElement('a');
      parser.href = url;

      return parser.hostname;
    } else {
      return window.location.hostname;
    }
  })(url);

  return host !== linkHost;
};

const Link = ({ url, blank, children, title, icon, ...rest }) => {
  const content = () => {
    if (children) {
      return children;
    }

    if (icon) {
      return <img src={icon.url} alt={icon.alt || title} />;
    }

    return <span>{title}</span>;
  };
  if (isExternalLink(url) || blank) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {content()}
      </a>
    );
  }
  return (
    <RouterLink to={url} {...rest}>
      {content()}
    </RouterLink>
  );
};

Link.defaultProp = {
  blank: true,
};

Link.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  blank: PropTypes.bool,
  icon: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string.isRequired,
    format: PropTypes.string,
    alt: PropTypes.string,
  }),
  rest: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Link;
