const LOGIN = 'app/authentication/LOGIN';
const LOGIN_REQUESTED = 'app/authentication/LOGIN_REQUESTED';
const LOGIN_SUCCEEDED = 'app/authentication/LOGIN_SUCCEEDED';
const LOGIN_FAILED = 'app/authentication/LOGIN_FAILED';

const GET_PROFILE = 'app/authentication/GET_PROFILE';
const GET_PROFILE_REQUESTED = 'app/authentication/GET_PROFILE_REQUESTED';
const GET_PROFILE_SUCCEEDED = 'app/authentication/GET_PROFILE_SUCCEEDED';
const GET_PROFILE_FAILED = 'app/authentication/GET_PROFILE_FAILED';

const GET_KITS = 'app/authentication/GET_KITS';
const GET_KITS_REQUESTED = 'app/authentication/GET_KITS_REQUESTED';
const GET_KITS_SUCCEEDED = 'app/authentication/GET_KITS_SUCCEEDED';
const GET_KITS_FAILED = 'app/authentication/GET_KITS_SUCCEEDED';

export default {
  LOGIN,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,

  GET_PROFILE,
  GET_PROFILE_REQUESTED,
  GET_PROFILE_SUCCEEDED,
  GET_PROFILE_FAILED,

  GET_KITS,
  GET_KITS_REQUESTED,
  GET_KITS_SUCCEEDED,
  GET_KITS_FAILED,
};
