import NetworkError from './NetworkError';

// https://developers.facebook.com/docs/instagram/embedding#get--p--post-url--media
export function getEmbeddedContent(postUrl) {
  return fetch(`https://api.instagram.com/oembed?url=${postUrl}`).then(res =>
    res.json().then(body => {
      if (!res.ok) {
        throw new NetworkError(res, body);
      }
      return body;
    }),
  );
}
