import React from 'react';
import PropTypes from 'prop-types';

import BannerContent from './BannerContent';
import DescriptionContent from './DescriptionContent';
import DescriptionVideoContent from './DescriptionVideoContent';
import DescriptionImageContent from './DescriptionImageContent';
import VideosContent from './VideosContent';
import CardsContent from './CardsContent';
import GuidesContent from './GuidesContent';
import InstagramContent from './InstagramContent';
import HeroBannerContent from './HeroBannerContent';
import TwoColumnsContent from './TwoColumnsContent';
import EventCardsContent from './EventCardsContent';

import './ContentsPage.scss';

const ContentItems = {
  DescriptionRecord: DescriptionContent,
  DescriptionVideoRecord: DescriptionVideoContent,
  DescriptionImageRecord: DescriptionImageContent,
  BannerRecord: BannerContent,
  VideoListRecord: VideosContent,
  CardListRecord: CardsContent,
  GuidesInfoRecord: GuidesContent,
  InstagramRecord: InstagramContent,
  HeroBannerRecord: HeroBannerContent,
  TwoColumnsContentRecord: TwoColumnsContent,
  EventCardListRecord: EventCardsContent,
};

const ContentsPage = ({ contents }) => {
  return contents.map(item => {
    const Tag = ContentItems[item.__typename];
    if (Tag) {
      return <Tag key={item.id} {...item} className="content-page" />;
    } else {
      // eslint-disable-next-line no-console
      console.error('Unknown type', item.__typename);
      return null;
    }
  });
};

ContentsPage.propTypes = {
  contents: PropTypes.array,
};

export default ContentsPage;
