import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';
import CardList from './CardList';

const CardsContent = ({ id, title, description, cards, style }) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div
      className={classnames('content-page', 'cards-content', blockClassName)}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        <div
          className="content-page__title"
          dangerouslySetInnerHTML={{ __html: title }}></div>

        {description && (
          <div className="content-page__content">
            <div
              className="content-page__description"
              dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        )}

        <div className="content-page__box">
          <CardList list={cards} />
        </div>
      </div>
    </div>
  );
};

CardsContent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      button: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  ),
  style: StyleRecord,
};

export default CardsContent;
