import React from 'react';
import PropTypes from 'prop-types';

const TextObject = ({ __typename, heading, secondary, name, paragraphs }) => {
  return (
    <div className={`two-columns-content__text-record type--${__typename}`}>
      {(heading || (secondary && name)) && (
        <div
          className="two-columns-content__title"
          dangerouslySetInnerHTML={{
            __html: heading || (secondary && name),
          }}></div>
      )}
      <div
        className="two-columns-content__text"
        dangerouslySetInnerHTML={{ __html: paragraphs }}
      />
    </div>
  );
};

TextObject.defaultProps = {
  secondary: false,
};

TextObject.propTypes = {
  __typename: PropTypes.string,
  heading: PropTypes.string,
  paragraphs: PropTypes.string,
  name: PropTypes.string,
  secondary: PropTypes.bool,
};

export default TextObject;
