import types from './types';

const login = redirect => ({
  type: types.LOGIN,
  redirect,
});

const loginRequested = () => ({
  type: types.LOGIN_REQUESTED,
});

const loginSucceeded = profile => ({
  type: types.LOGIN_SUCCEEDED,
  profile,
});

const loginFailed = error => ({
  type: types.LOGIN_FAILED,
  error,
});

const getProfile = () => ({
  type: types.GET_PROFILE,
});

const getProfileRequested = () => ({
  type: types.GET_PROFILE_REQUESTED,
});

const getProfileSucceeded = profile => ({
  type: types.GET_PROFILE_SUCCEEDED,
  profile,
});

const getProfileFailed = error => ({
  type: types.GET_PROFILE_FAILED,
  error,
});

const getKits = () => ({
  type: types.GET_KITS,
});

const getKitsRequested = () => ({
  type: types.GET_KITS_REQUESTED,
});

const getKitsSucceeded = kits => ({
  type: types.GET_KITS_SUCCEEDED,
  kits,
});

const getKitsFailed = error => ({
  type: types.GET_KITS_FAILED,
  error,
});

export default {
  login,
  loginRequested,
  loginSucceeded,
  loginFailed,
  getProfile,
  getProfileRequested,
  getProfileSucceeded,
  getProfileFailed,
  getKits,
  getKitsRequested,
  getKitsSucceeded,
  getKitsFailed,
};
