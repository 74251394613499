import React from 'react';
import PropTypes from 'prop-types';

const FeatureObject = ({ __typename, title, description }) => {
  return (
    <div className={`two-columns-content__feature-record type--${__typename}`}>
      {title && (
        <div className="two-columns-content__feature-title">{title}</div>
      )}
      {description && (
        <div
          className="two-columns-content__feature-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};

FeatureObject.propTypes = {
  __typename: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FeatureObject;
