import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Style, { StyleRecord } from '../StyleGenerator';

const DescriptionContent = ({
  tag,
  title,
  description,
  subtitle,
  style,
  id,
}) => {
  const blockClassName = `content-page-${id}`;
  return (
    <div className={classnames('content-page', 'description', blockClassName)}>
      <Style blockClassName={blockClassName} style={style} />
      <div className="main-container">
        {tag && (
          <div
            className="content-page__tag"
            dangerouslySetInnerHTML={{ __html: tag }}></div>
        )}
        {title && (
          <div
            className="content-page__title"
            dangerouslySetInnerHTML={{ __html: title }}></div>
        )}

        <div className="content-page__content">
          <div className="content-page__text-box">
            {subtitle && (
              <div
                className="content-page__description"
                dangerouslySetInnerHTML={{ __html: subtitle }}></div>
            )}
            {description && (
              <div
                className="content-page__text-content"
                dangerouslySetInnerHTML={{ __html: description }}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DescriptionContent.propTypes = {
  id: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  style: StyleRecord,
};

export default DescriptionContent;
